import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  templatesInitialized: false,
  initialized: false,
  error: null,
  settings: {},
  templates: [],
  customersList: [],
  customersListLoader: false,
  rates: [],
  ratesLoader: false,
  updateRatesLoader: false,
  getTemplatesLoader: false,
};

const whatsAppSlice = createSlice({
  name: 'whatsApp',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // SET SETTINGS SUCCESS
    setSettings(state, action) {
      const details = action.payload;
      const availableSettings = {
        type: details?.type,
        details: details && JSON.parse(details?.details || {}),
      };
      state.settings = availableSettings;
      state.initialized = true;
    },

    // SET TEMPLATES SUCCESS
    setTemplates(state, action) {
      state.templatesInitialized = true;
      state.templates = action?.payload || [];
    },

    // START CUSTOMERS LIST LOADING
    startCustomersListLoader(state) {
      state.customersListLoader = true;
    },
    // STOP CUSTOMERS LIST LOADING
    stopCustomersListLoader(state) {
      state.customersListLoader = false;
    },
    // SET CUSTOMERS LIST SUCCESS
    setCustomersList(state, action) {
      const { childs } = action.payload;
      state.customersListLoader = false;
      state.customersList = childs || [];
    },

    // START RATES LOADING
    startRatesLoader(state) {
      state.ratesLoader = true;
    },
    // STOP RATES LOADING
    stopRatesLoader(state) {
      state.ratesLoader = false;
    },
    // SET RATES SUCCESS
    setRates(state, action) {
      const { rates } = action.payload;
      state.ratesLoader = false;
      state.rates = rates || [];
    },

    // START RATES LOADING
    startUpdateRatesLoader(state) {
      state.updateRatesLoader = true;
    },
    // STOP RATES LOADING
    stopUpdateRatesLoader(state) {
      state.updateRatesLoader = false;
    },

    // START RATES LOADING
    startGetTemplatesLoader(state) {
      state.getTemplatesLoader = true;
    },
    // STOP RATES LOADING
    stopGetTemplatesLoader(state) {
      state.getTemplatesLoader = false;
    },
  },
});

// Actions
export const { setSettings, setTemplates } = whatsAppSlice.actions;

// Reducer
export default whatsAppSlice.reducer;

export const getSettings = (state) => state.whatsApp;

export function getSettingsFromApi() {
  return async (dispatch) => {
    try {
      const response = await axios.get('lms_user/api/partner/whatsapp/settings');
      dispatch(whatsAppSlice.actions.setSettings(response.data.details));
    } catch (error) {
      dispatch(whatsAppSlice.actions.hasError(error));
    }
  };
}

export function storeSettings(data) {
  return async (dispatch) => {
    try {
      const response = await axios.post('lms_user/api/partner/whatsapp/settings', { params: data });
      dispatch(whatsAppSlice.actions.setSettings(response.data.details));
    } catch (error) {
      dispatch(whatsAppSlice.actions.hasError(error));
    }
  };
}

export function getTemplatesFromApi(type) {
  return async (dispatch) => {
    dispatch(whatsAppSlice.actions.startGetTemplatesLoader());
    try {
      const response = await axios.get(`lms_user/api/partner/whatsapp/${type}/templates`);
      dispatch(whatsAppSlice.actions.setTemplates(response.data.templates));
      dispatch(whatsAppSlice.actions.stopGetTemplatesLoader());
    } catch (error) {
      dispatch(whatsAppSlice.actions.stopGetTemplatesLoader());
      dispatch(whatsAppSlice.actions.hasError(error));
    }
  };
}

export function getCustomersList() {
  return async (dispatch) => {
    dispatch(whatsAppSlice.actions.startCustomersListLoader());
    try {
      const response = await axios.get(`lms_user/api/partner/whatsapp/rates/childs`);
      dispatch(whatsAppSlice.actions.setCustomersList(response.data));
      dispatch(whatsAppSlice.actions.stopCustomersListLoader());
    } catch (error) {
      dispatch(whatsAppSlice.actions.stopCustomersListLoader());
      dispatch(whatsAppSlice.actions.hasError(error));
    }
  };
}

export function getCustomersRates(typ, typeId) {
  return async (dispatch) => {
    dispatch(whatsAppSlice.actions.startRatesLoader());
    try {
      const response = await axios({
        method: 'get',
        url: `/lms_user/api/partner/whatsapp/rates`,
        params: {
          type: typ,
          type_id: typeId,
        },
      });
      dispatch(whatsAppSlice.actions.setRates(response.data));
      dispatch(whatsAppSlice.actions.stopRatesLoader());
    } catch (error) {
      dispatch(whatsAppSlice.actions.stopRatesLoader());
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function updateCustomersRates(newRates) {
  return async (dispatch) => {
    dispatch(whatsAppSlice.actions.startUpdateRatesLoader());
    try {
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/partner/whatsapp/rates`,
        data: newRates,
      });
      dispatch(whatsAppSlice.actions.setCustomersList(response.data));
      dispatch(whatsAppSlice.actions.stopUpdateRatesLoader());
    } catch (error) {
      dispatch(whatsAppSlice.actions.stopUpdateRatesLoader());
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}
