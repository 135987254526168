import { combineReducers } from 'redux';
// slices
import customersReducer from './customers/customers.slice';
import partnersReducer from './partners/partners.slice';
import accountSetupReducer from './partners/accountSetup.slice';
import usersReducer from './users/users.slice';
import whatsAppReducer from './whatsApp/whatsApp.slice';
import reportsReducer from './reports/reports.slice';
import googleWorkspaceReducer from './googleWorkspace/googleWorkspace.slice';

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  customers: customersReducer,
  partners: partnersReducer,
  accountSetup: accountSetupReducer,
  users: usersReducer,
  whatsApp: whatsAppReducer,
  reports: reportsReducer,
  googleWorkspace: googleWorkspaceReducer,
});

export default rootReducer;
